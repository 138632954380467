import React from "react"
//import { Link } from "gatsby"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"


import closeChat from "../images/close-icon.svg"
import failImg from "../images/gray/block.svg"

import Layout from "../components/layout"
import Wizard from "../components/wizard"
import MyInput from "../components/MyInput"

import yourInfoIcon from "../images/gray/2.svg"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import ButtonLoader from "../components/buttonLoader";
import ErrrorHandel from "../components/ErrrorHandel";
import { restrictRouteForStep } from "../core/util";
import MyInputMask from "../components/MyInputMask";
//import { isAuthenticated,getAuthenticatedUser } from '../core/util';


class YourInfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            zoneAvl:null,
            plans:null,
            planAvl:null,
            showalert:false,
        };
    }

    async componentDidMount() {
        this.props.showLoader();
        if (this.props.step2response && !this.props.step2response.recordId) {
            let paramId = restrictRouteForStep(this.props.location);
            if(paramId){
                await this.getFromDirect(paramId);
            } else {
                if(this.props.step1Data  && this.props.step1Data.vehicleInfo)
                    this.getPlan();
            }
        } else {
           
            if(this.props.step1Data  && this.props.step1Data.vehicleInfo)
                this.getPlan();
        }
        /* if (isAuthenticated()) {
            let usrdata = getAuthenticatedUser();
            this.props.saveSecondStep(usrdata);
        } */
        this.props.hideLoader();
    }


    getFromDirect = async(paramId) => {
        
        const { saveSecondStep, saveFirstStep, saveThirdStep, saveFourthStep, saveSecondStepResponse, saveThirdStepResponse } = this.props;
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getPlanById'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                _id: paramId,
            })
          })
        .then(res => res.json())
        .then((res) => {
            if(res.status == 1){
                let secondstepResponse = {
                    userId: res.data.step3.userId,
                    vehicleId: res.data.step3.vehicleId,
                    recordId: res.data.step3.recordId
                }
                
                sessionStorage['plans'+(global.dealertoken?global.dealertoken:'')] = JSON.stringify(res.data.plans);
                this.state.planAvl = true;
                this.state.zoneAvl =true;

                saveFirstStep(res.data.step1);
                saveSecondStep(res.data.step2);
                saveSecondStepResponse(secondstepResponse);
                saveThirdStep(res.data.step3);
                saveThirdStepResponse(res.data.step3);
                saveFourthStep(res.data.step4);
            } else {
                navigate("/vehicleInfo/?q="+paramId+(global.dealertoken?'&dealertoken='+global.dealertoken:''))
            }
        });
        
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        if(this.state.zoneAvl!==null){
            let curval = this.refs[`yourInfoForm`].getCurrentValues();
            if(curval.firstName && curval.lastName  && curval.email)
            this.setState({ canSubmit: true });
        }
           
    }

    getPlan = () => {
        const { step1Data, showButtonLoader, hideButtonLoader } = this.props;
        showButtonLoader();
          let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getPlanByData'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
          fetch(uri,{
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(step1Data)
            })
          .then(res => res.json())
          .then((res) => {
            if(res.zone){
                this.setState({ zoneAvl: true})
                if(res.plans.plan1){
                    this.setState({ plans: res.plans,})
                    this.setState({ planAvl: true})
                    sessionStorage['plans'+(global.dealertoken?global.dealertoken:'')] = JSON.stringify(res.plans);
                } else {
                    this.setState({ planAvl: false})
                }
            } else {
                this.setState({ zoneAvl: false})
            }
            this.enableButton();
            hideButtonLoader();
          });
    }
    
    getUtmData(){
        if(localStorage.utmData.includes("GA_Client_ID"))
          return JSON.parse(localStorage.utmData)
        else
          return JSON.parse(window.atob(localStorage.utmData))
    }

    submit = (model) => {
        const { saveSecondStep,showLoader, saveSecondStepResponse, step1Data} = this.props;
        showLoader();

        if(!global.dealertoken){
            var utmData = this.getUtmData()
            utmData.lastonholdtime = null;
            var uniq = model.email;
        }
        
        
        if(this.state.planAvl && this.state.zoneAvl){
            if(!global.dealertoken){
                if(utmData.last_ad_click){
                    let dif_click = Math.floor(((new Date().getTime() / 1000) - utmData.last_ad_click)/86400);
                    if(utmData.ad_lead_gen){
                        let dif_leadgen = Math.floor(((new Date().getTime() / 1000) - utmData.ad_lead_gen)/86400);
                        if(dif_click<=30 && dif_leadgen>30){
                            utmData.lastonholdtime = new Date(utmData.last_ad_click*1000).toISOString();
                        }
                    } else {
                        if(dif_click<=30){
                            utmData.lastonholdtime =  new Date(utmData.last_ad_click*1000).toISOString();
                        }
                    }
                }
            }
            let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/adduser'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    firstName: model.firstName,
                    lastName: model.lastName,
                    email: model.email,
                    phone: model.phone,
                    vehicleInfo: step1Data.vehicleInfo,
                    vehicleFeature:step1Data.vehicleFeature,
                    dealerId:step1Data.dealerId?step1Data.dealerId:null,
                    campaign:utmData,
                    plans:JSON.parse(sessionStorage['plans'+(global.dealertoken?global.dealertoken:'')])
                })
              })
            .then(res => res.json())
            .then((res) => {
                if (res.data) {
                   let info = {
                        firstName: model.firstName,
                        lastName: model.lastName,
                        email: model.email,
                        phone: model.phone,
                        address : res.data.address,
                        state : res.data.state,
                        city : res.data.city,
                    };
                    //password : res.data.password

                    /* if(!global.dealertoken){
                        if(res.data.isNewCust===1 && utmData.lastonholdtime){
                            //let convresp = window.gtag('event', 'conversion', {'send_to': 'AW-714635181/Hxu3CLfkxbMBEK3v4dQC'});
                            //'send_to': 'AW-679984203/mN6DCI_ihrkBEMv4nsQC','transaction_id': uniq,
                            if (typeof window.gtag === "function"){
                                window.gtag('event', 'conversion', {
                                    'send_to': 'AW-714635181/Hxu3CLfkxbMBEK3v4dQC','transaction_id': uniq,
                                    'event_callback': function(r1,r2) {
                                        utmData.pages_visited_list = '';
                                        utmData.ad_lead_gen = utmData.last_ad_click;
                                        localStorage.setItem("utmData", window.btoa(JSON.stringify(utmData)));
                                    }
                                });
                            } else {
                                console.log("window.gtag not avl"); 
                            }
                        }
                    } */
                    

                    saveSecondStep(info);
                    let step2response = {
                        recordId: res.data.recordId,
                        vehicleId : res.data.vehicleId,
                        userId: res.data.userId,
                    }
                    saveSecondStepResponse(step2response);
                    
                    if(res.data.recordId){
                        if(global.dealertoken && global.href) this.ahrefElement.click();
                        navigate(`/customizePlan/?q=${res.data.recordId}`+(global.dealertoken?'&dealertoken='+global.dealertoken:''));
                    } else {
                        navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                    }
                }
            });
        } else {
            let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/addLead'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    firstName: model.firstName,
                    lastName: model.lastName,
                    email: model.email,
                    phone: model.phone,
                    campaign:utmData,
                    zoneAvl:this.state.zoneAvl,
                    vehicleInfo: step1Data.vehicleInfo,
                    vehicleFeature:step1Data.vehicleFeature,
                })
            });
            let info = {
                firstName: model.firstName,
                lastName: model.lastName,
                email: model.email,
                phone: model.phone
            };
            saveSecondStep(info);
            this.setState({ showalert: true})
        }
    }

    
    render() {
        const { canSubmit,showalert,zoneAvl } = this.state;
        const { step2Data, buttonLoader } = this.props;
        return (
            <Layout layoutType="main">
                <SEO title="Servicecontract.com - Your Info" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                {global.dealertoken?
                <a className="hideme" id="price-step3" href={global.href+"#price-step3:"+(step2Data.email ? step2Data.email : '')} ref={ahref => this.ahrefElement = ahref} target="_top">trigger</a>
                :''}
                <div className="chat-content">-
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                        <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                            <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                                <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                                <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                            </g>
                        </g>
                    </svg>-
                </div>
                {showalert?
                <div className="success-container">
                    <div className="success-inner small">
                        <div onClick={() => navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))} className="close-btn close">
                            <img className="" src={closeChat} alt="" />
                        </div>

                        <div className="content">
                            <div className="subdiv">
                            <div className="subleft">
                            <img src={failImg} alt=""/>
                            </div>
                            <div className="subright">
                                {zoneAvl===false?
                                    <span>We’re sorry, we don’t have coverage in your state yet, but will soon. We will email you as soon as we have something available for you.</span>
                                :
                                    <span>We’re sorry, we don’t have coverage in your vehicle and mileage.</span>
                                }
                            </div>
                            </div>
                            <div onClick={() => navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))} className="button">
                                <button className={global.dealertoken?'btn grayish':'btn'}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                :''}
                <section className={global.dealertoken?'grayish':''}>
                    <Wizard></Wizard>
                    <div className="custom-container vehinfo">

                        <div className="title-container">
                            <div className="icon-image">
                                <img src={yourInfoIcon} alt="icon" />
                            </div>
                            <div className="title-text">
                                <h1>Your Info</h1>
                                <p>Providing the requested information below allows you to save your quote and come back later.</p>
                            </div>
                        </div>

                        <div className="body-content">
                            <h2 className="body-title mb-48 your-info-title">Ready to see your quote? 
                            {/* for your 
                            {step1Data.vehicleInfo && step1Data.vehicleInfo.maker ? step1Data.vehicleInfo.maker : ''} 
                            {step1Data.vehicleInfo && step1Data.vehicleInfo.model ? step1Data.vehicleInfo.model : ''}? */}</h2>
                            <Formsy ref="yourInfoForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                                <ErrrorHandel formName={'yourInfoForm'} self={this} />
                                
                                <div className="form-container-inline">
                                    <div className="form-left">
                                        <div className="form-label-group error">
                                            <MyInput
                                                name="firstName"
                                                id="firstName"
                                                placeholder="First Name"
                                                value={step2Data.firstName ? step2Data.firstName : ''}
                                                isLabelRequired={true}
                                                className="form-control"
                                                required
                                                validations={{minLength: 3,maxLength:25}}
                                                validationError="Please enter a valid firstname."
                                            />
                                        </div>

                                    </div>
                                    <div className="form-right">
                                        <div className="form-label-group error">
                                            <MyInput
                                                name="lastName"
                                                id="lastName"
                                                placeholder="Last Name"
                                                value={step2Data.lastName ? step2Data.lastName : ''}
                                                isLabelRequired={true}
                                                className="form-control"
                                                required
                                                validations={{minLength: 3,maxLength:25}}
                                                validationError="Please enter a valid lastname."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-container-inline">
                                    <div className="form-left">
                                        <div className="form-label-group error">
                                            <MyInput
                                                name="email"
                                                id="email"
                                                validations="isEmail"
                                                validationError="Please enter valid email"
                                                placeholder="Email"
                                                value={step2Data.email ? step2Data.email : ''}
                                                isLabelRequired={true}
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-right">
                                        <div className="form-label-group error">
                                            <MyInputMask
                                                name="phone"
                                                id="phone"
                                                mask="999-999-9999"
                                                placeholder="Phone"
                                                validations={{
                                                    minLength: 12
                                                }}
                                                validationError="Please enter a valid mobile number."
                                                value={step2Data.phone ? step2Data.phone : ''}
                                                isLabelRequired= {true}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="body-text agretxt">
                                    <p>Information submitted is private and secure. Read our Privacy Policy.
                                    </p>
                                    <p>By clicking the Continue button, you agree to receive phone calls, emails, and text messages at the phone number or email address you provided, which may be through an automatic telephone dialing or texting system. Consent is not required to purchase a service contract.</p>
                                </div>
                                
                                <div className="btn-container">
                                    <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn your-info-btn' : 'btn wizard-btn your-info-btn'}>
                                        <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                                    </button> 
                                </div>
                            </Formsy>
                            <div className="txtright">* Field is required</div>
                        </div>
                    </div>
                    {global.dealertoken?'':
                    <div className="vehicle-bottom-line bottom-line">
                        <img src={bottomLine} alt="line" />
                    </div>
                    }
                </section>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
        step1Data: state.register.step1,
        step2Data: state.register.step2,
        step2response: state.register.step2response
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader()),
        saveSecondStep: (formData) => dispatch(actionCreators.saveSecondStep(formData)),
        saveSecondStepResponse: (response) => dispatch(actionCreators.saveSecondStepResponse(response)),
        saveFirstStep: (formData) => dispatch(actionCreators.saveFirstStep(formData)),
        saveThirdStep: (formData) => dispatch(actionCreators.saveThirdStep(formData)),
        saveThirdStepResponse: (response) => dispatch(actionCreators.saveThirdStepResponse(response)),
        saveFourthStep: (formData) => dispatch(actionCreators.saveFourthStep(formData)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(YourInfoPage);

